import React, { useState } from 'react'
import { Dropdown } from '@posten-hedwig/dropdown'
import { Block } from '@posten/hedwig-react'
import './country-select.scss'

const CountrySelect = ({ countries, variant, placeholder, linkColor }) => {
    const [selected, setSelected] = useState()

    const handleOnSelect = (val) => {
        setSelected(countries.find((element) => element.value === val))
    }

    return (
        <>
            <div>
                <Dropdown
                    variant={variant}
                    options={countries}
                    placeholder={placeholder} 
                    onChange={(val) => handleOnSelect(val)}
                ></Dropdown>
            </div>
            {selected && (
                <Block mt='medium-2'>
                    <ul class='hw-linklist'>
                        {selected.urls.map((link, index) => (
                            <li key={index}>
                                <a href={link.url} className={linkColor}>{link.url}</a>
                            </li>
                        ))}
                    </ul>
                </Block>
            )}
        </>
    )
}

export default (props) => <CountrySelect {...props} />
